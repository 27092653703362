<template>
    <section class="page-section">
        <div class="section-title-wrapper">
            <Skeletor class="section-title" width="30%" />
        </div>
        <div class="dosiin_brand-slider-vertical swiper-filter-container">
            <ul class="brand-highlight_list">
                <li class="brand-highlight-item_wrapper dosiin_brand-follow-wrapper brand-item" v-for="i in 5" :key="i">
                    <div class="brand-highlight-item_img-wrapper brand-item_img-wrapper">                    
                         <Skeletor width="62" height="62" circle />              
                    </div>
                    <div class="brand-highlight-item_info">
                        <a> 
                            <h5 class="brand-item_title">
                                <Skeletor width="100%" />
                            </h5>
                        </a>
                         <dl class="brand-item_social">
                            <dd><Skeletor width="100%" /></dd>
                            <dd><Skeletor width="50%" /></dd>
                        </dl>
                    </div>
                    <div class="brand-highlight-item_follow-btn">
                        <button class="brand_follow-btn dosiin_brand_follow-btn">  
                            <Skeletor width="40" height="40" circle/>
                        </button>                
                    </div>
                </li>
            </ul>
        </div>
        <div class="seemore-btn">        
            <a>            
                 <Skeletor width="100%" height="25" pill /> 
            </a>    
        </div>
    </section>
</template>