<template>
  <main class="home-new-brand">
    <BackHeader>
      <template v-slot:center>
        <h2 class="text-grey-lg title-header-category">Thương hiệu nổi bật</h2>
      </template>
      <template v-slot:right>
        <div class="back-header-right-side">
          <router-link
            :to="{ name: 'search' }"
            class="search-icon dosiin_search-header-btn"
          >
            <i class="dsi dsi-search"></i>
          </router-link>
        </div>
      </template>
    </BackHeader>

    <Intersection @onIntersection="onIntersectionBrandHightlight()">
      <BrandVerticalSkeleton v-if="state.brandsHighLight.length === 0" />

      <template v-else>
        <hgroup class="product-page-header_wrap">
          <h1 class="title">Thương hiệu nổi bật</h1>
          <h2 class="subtitle">Các thương hiệu nổi bật của Việt Nam</h2>
        </hgroup>

        <section
          class="page-section brand-sub-page-section brand-sub-page_brands-section"
        >
          <div class="best-sellings">
            <div class="best-sellings-wrapper" id="dosiin_best-selling">
              <ul class="brand-highlight_list">
                <li
                  class="brand-highlight-item_wrapper dosiin_brand-follow-wrapper brand-item"
                  v-for="(brand, i) in state.brandsHighLight"
                  :key="i"
                >
                  <div class="brand-highlight-item_img-wrapper brand-item_img-wrapper">
                    <BrandLogo :brand="brand" :width="62" :height="62" />
                  </div>
                  <div class="brand-highlight-item_info">
                    <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                      <h5
                        class="brand-item_title"
                        :title="brand.company"
                        v-text="brand.company"
                      ></h5>
                    </router-link>
                    <dl class="brand-item_social">
                      <dd class="brand-highlight-item_n-followers">
                        <span class="dosiin_brand-follow-number">{{
                          brand.follow_count
                        }}</span>
                        người theo dõi
                      </dd>
                      <dd class="brand-highlight-item_n-likes">
                        {{ brand.like_count }} người thích trang
                      </dd>
                    </dl>
                  </div>
                  <div class="brand-highlight-item_follow-btn">
                    <FollowButton
                      @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                      class="brand_follow-btn primary-button button"
                      followable_type="brand"
                      :followable_id="brand.company_id"
                      :is_follow="brand.is_follow"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </template>
    </Intersection>

    <Intersection @onIntersection="onIntersectionProductFavorite()">
      <ProductsSliderHorizon
        wrapperClass="page-section"
        title="Sản phẩm bán chạy"
        gridClass="grid-33"
        :products="state.productsBestSelling"
        :options="{
          slidesPerView: 2.5,
          slidesPerGroup: 2,
          spaceBetween: 9,
        }"
      />
    </Intersection>
  </main>
</template>

<script>
import BackHeader from "@/components/common/BackHeader";
import BrandsNewArrivalMB from "@/components/skeletons/brand/BrandsNewArrivalMB";
import BrandVerticalSkeleton from "@/components/skeletons/brand/BrandsHightlight";
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import { inject } from "@vue/runtime-core";

export default {
  components: {
    BackHeader,
    BrandsNewArrivalMB,
    BrandVerticalSkeleton,
    ProductsSliderHorizon,
  },
  setup() {
    const state = inject("state");
    const onIntersectionBrandHightlight = inject("onIntersectionBrandHightlight");
    const onIntersectionProductFavorite = inject("onIntersectionProductFavorite");

    return {
      state,
      onIntersectionBrandHightlight,
      onIntersectionProductFavorite,
    };
  },
};
</script>
