<template>
    <BrandsHighLightMB v-if="layout == 4 " />
    <BrandsHighLightPC v-else />
</template>

<script>
    import BrandsHighLightMB from '@/pages/mb/BrandsHighLight';
    import BrandsHighLightPC from '@/pages/pc/BrandsHighLight';
    import { provide, reactive } from 'vue';
    import BrandServices from '@/services/brand';
    import DataService  from '@/services/dosiin';
    import { serialize } from '@/utlis/serialize';

    export default {
        components:{
            BrandsHighLightMB,
            BrandsHighLightPC
        },
        setup() {
            const state = reactive({
                brandsHighLight : [],
                productsBestSelling : [],
                brandsSugestion : []
            });

            async function onIntersectionBrandHightlight(){
                const response = await DataService.fetchData(serialize({
                    'getBrand'  : true,
                    'get_banner' : true,
                    'get_follow' : true,
                    'type' : 'highlight',
                    'limit'  : 50
                }));

                if(response.data){
                    state.brandsHighLight = Object.values(response.data.brands);
                }
            }

            async function onIntersectionBrandSugestion(){
                const response = await BrandServices.fetchBrands(serialize({
                    'sort_by' : 'random',
                    'sort_order' : 'desc',
                    'get_banner' : true,
                    'get_follow' : true,
                    'limit'  : 20,
                    'level_id' : 20,
                    'status' : 'A'
                }));

                if(response.data){
                    state.brandsSugestion = Object.values(response.data.brands);
                }
            }

            async function onIntersectionProductFavorite () {
                const response = await DataService.fetchData(serialize({ type : 'best_like' , limit : 21}));
                if(response.data){
                    state.productsBestSelling = Object.values(response.data.products);
                }
            }

            provide('state' , state);
            provide('onIntersectionBrandHightlight' , onIntersectionBrandHightlight);
            provide('onIntersectionBrandSugestion', onIntersectionBrandSugestion);
            provide('onIntersectionProductFavorite', onIntersectionProductFavorite);
        },
    }
</script>